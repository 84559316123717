import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AgmCoreModule } from '@agm/core';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

//FIREBAE
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireAuthGuard } from '@angular/fire/auth-guard';

//PAGES
import { HomeComponent } from './pages/home/home.component';
import { Service1Component } from './pages/service1/service1.component';
import { Service2Component } from './pages/service2/service2.component';
import { Service3Component } from './pages/service3/service3.component';
import { ContactComponent } from './pages/contact/contact.component';
import { AboutComponent } from './pages/about/about.component';
import { FooterComponent } from './modules/footer/footer.component';

export const firebaseConfig = {
  apiKey: "AIzaSyAEsPyzMYDm0c2MIj3wbejagSEucw39CTs",
  authDomain: "verrocchi-d0f5e.firebaseapp.com",
  databaseURL: "https://verrocchi-d0f5e.firebaseio.com",
  projectId: "verrocchi-d0f5e",
  storageBucket: "verrocchi-d0f5e.appspot.com",
  messagingSenderId: "84241779448",
  appId: "1:84241779448:web:f85526204f86a3b4dac90d",
  measurementId: "G-QTSJ8S04F5"
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    Service1Component,
    Service2Component,
    Service3Component,
    ContactComponent,
    AboutComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireDatabaseModule,
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features,
    AngularFireStorageModule, // imports firebase/storage only needed for storage features
    FlexLayoutModule,
    CarouselModule,
    BrowserAnimationsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCXeO6OZez8_J95_DptlEwrCEpuMgrd3R8'
   })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
