import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public slidesHomeDesktop = [
    {
      id:'',
      src:'./assets/images/home/Home_slider_01_desk.jpg',
      alt:'',
      title:'', 
    },
    {
      id:'',
      src:'./assets/images/home/Home_slider_02_desk.jpg',
      alt:'',
      title:'', 
    },
    {
      id:'',
      src:'./assets/images/home/Home_slider_03_desk.jpg',
      alt:'',
      title:'', 
    }
  ];

  public slidesHomeMobile = [
    {
      id:'',
      src:'./assets/images/home/Home_slider_01_mobile.jpg',
      alt:'',
      title:'', 
    },
    {
      id:'',
      src:'./assets/images/home/Home_slider_02_mobile.jpg',
      alt:'',
      title:'', 
    },
    {
      id:'',
      src:'./assets/images/home/Home_slider_03_mobile.jpg',
      alt:'',
      title:'', 
    }
  ];

  constructor(private router: Router, ) { }

  ngOnInit(): void {
    window.scroll(0,0);
  }

  public menu = false;
  openMenu(){
    if(this.menu){
      this.menu = false;
    }else{
      this.menu = true;
    }
  }

  openLink(link){
    this.menu = false;
    this.router.navigate([link]);
  }
}
