<section fxHide fxShow.md fxShow.gt-md>
    <div style="position: relative;">
        <owl-carousel-o [options]="{items: 1, dots:false, navigation:false, nav:false, autoplay:true, autoplayTimeout:3000, loop:true}" #owlCar1>
            <ng-container *ngFor="let slide of slidesHomeDesktop">
                <ng-template carouselSlide [id]="slide.id">
                    <div [style.backgroundImage]="'url(' + slide.src + ')'" style="width: 100%; height:100vh; background-size:cover; background-position:center;"></div>
                </ng-template>
            </ng-container>
        </owl-carousel-o>

        <div style="width: 100%; position: absolute; z-index: 9; top: 0px; background-color: rgba(0,0,0,0.3);"> 
            <div fxLayout="row" fxLayoutAlign="space-between center" style="height: 150px;">
                <div style="padding-left: 60px;">
                    <img src="./assets/verrocchi_bianco.svg" width="150px">
                </div>
                <div fxLayout="row" fxLayoutAlign="end center" style="padding-right: 60px;">
                    <div>
                        <button routerLink="/home" style="border-bottom: 2px solid white;" class="button-menu">HOME</button>
                    </div>
                    <div>
                        <button routerLink="/spedizioni_internazionali" class="button-menu">SPEDIZIONI INTERNAZIONALI</button>
                    </div>
                    <div>
                        <button routerLink="/dogana" class="button-menu">DOGANA</button>
                    </div>
                    <div>
                        <button routerLink="/logistica" class="button-menu">LOGISTICA</button>
                    </div>
                    <div>
                        <button routerLink="/about" class="button-menu">ABOUT US</button>
                    </div>
                    <div>
                        <button routerLink="/contact" class="button-menu">CONTATTI</button>
                    </div>
                </div>
            </div>
            <div fxLayout="column" fxLayoutAlign="center center" style="height: calc( 100vh - 200px); width: 100%;">
                <div class="font-40-700 color-white" style="text-align: center; font-size: 60px; line-height: 60px;">
                    <img src="./assets/verrocchi_bianco.svg" width="350px">
                </div>
                
                <div style="margin-top:15px">
                    <button routerLink="/about" class="button-white">ABOUT US</button>
                </div>
                
            </div>
            <div style="height: 50px;">
                <div class="owl-carousel owl-theme owl-loaded">
                    <div class="owl-dots">
                        <div class="owl-dot active"><span></span></div>
                        <div class="owl-dot"><span></span></div>
                        <div class="owl-dot"><span></span></div>
                    </div>
                </div>
            </div>  
        </div>
    </div>

    <div style="padding-left: 30px; padding-top: 30px; padding-right: 30px;">
        <div fxLayout="row" fxLayoutAlign="start start" style="background-image: url('./assets/images/home/Home_servizio_A_desk.jpg'); background-position: center; background-size: cover; height: 70vh;">
            <div fxFlex="50">

            </div>
            <div fxLayout="column" fxLayoutAlign="center center" fxFlex="50" style="padding-left: 30px; padding-right: 30px; background-color: rgba(230, 10, 0, 0.9); height: 70vh;">
                <div style="text-align: center;">
                    <img src="./assets/images/01shipping.svg" width="100px">
                </div>
                <div class="font-40-700 color-white" style="text-align: center; line-height: 40px; margin-top: 30px;">
                    SPEDIZIONI<br>INTERNAZIONALI
                </div>
                <div class="font-16-400 color-white" style="margin-top: 30px; text-align: center;">
                    Con sedi operative a Bari porto, interporto e zona industriale, e una recente sede branch in Albania, a Durazzo e Tirana, offriamo servizi di trasporti stradali, interzonali, marittimi e aerei.
                </div>
                <div style="margin-top: 30px;">
                    <button routerLink="/spedizioni_internazionali" class="button-white" style="text-transform: uppercase;">Scopri di più</button>
                </div>
            </div>
        </div>
    </div>

    <div style="padding-left: 30px; padding-top: 30px; padding-right: 30px;">
        <div fxLayout="row" fxLayoutAlign="start start" style="background-image: url('./assets/images/home/Home_servizio_B_desk.jpg'); background-position: center; background-size: cover; height: 70vh;">
            <div fxLayout="column" fxLayoutAlign="center center" fxFlex="50" style="padding-left: 30px; padding-right: 30px; background-color: rgba(0, 97, 195, 0.9); height: 70vh;">
                <div style="text-align: center;">
                    <img src="./assets/images/02crane.svg" width="100px">
                </div>
                <div class="font-40-700 color-white" style="text-align: center; line-height: 40px; margin-top: 30px;">
                    DOGANA
                </div>
                <div class="font-16-400 color-white" style="margin-top: 30px; text-align: center;">
                    Da oltre 40 anni, fiore all’occhiello della nostra attività, le operazioni doganali sono il nostro pane quotidiano, e il punto cruciale di ogni spedizione che coinvolga uno o più paesi comunitari ed extra-ue.
                </div>
                <div style="margin-top: 30px;">
                    <button routerLink="/dogana" class="button-white" style="text-transform: uppercase;">Scopri di più</button>
                </div>
            </div>
            <div fxFlex="50">

            </div>
        </div>
    </div>

    <div style="padding-left: 30px; padding-top: 30px; padding-right: 30px; padding-bottom: 30px;">
        <div fxLayout="row" fxLayoutAlign="start start" style="background-image: url('./assets/images/home/Home_servizio_C_desk.jpg'); background-position: center; background-size: cover; height: 70vh;">
            <div fxFlex="50">

            </div>
            <div fxLayout="column" fxLayoutAlign="center center" fxFlex="50" style="padding-left: 30px; padding-right: 30px; background-color: rgba(45, 195, 0, 0.9); height: 70vh;">
                <div style="text-align: center;">
                    <img src="./assets/images/03tracking.svg" width="100px">
                </div>
                <div class="font-40-700 color-white" style="text-align: center; line-height: 40px; margin-top: 30px;">
                    LOGISTICA
                </div>
                <div class="font-16-400 color-white" style="margin-top: 30px; text-align: center;">
                    A fronte di sempre più repentini cambiamenti del mercato e dei mutabili scenari del commercio internazionale, una lucida e reattiva gestione della catena logistica può fare la differenza nelle voci di bilancio aziendale
                </div>
                <div style="margin-top: 30px;">
                    <button routerLink="/logistica" class="button-white" style="text-transform: uppercase;">Scopri di più</button>
                </div>
            </div>
        </div>
    </div>


    <div style="background-image: url('./assets/images/home/Home_contattaci_desk.jpg'); background-position: center; background-size: cover; height: 50vh;">
        <div fxLayout="column" fxLayoutAlign="center center" style="padding-left: 30px; padding-right: 30px; background-color: rgba(0, 0, 0, 0.4); height: 50vh;">
            <div class="font-40-700 color-white" style="text-align: center; line-height: 40px; margin-top: 30px;">
                Flessibilità, praticità e semplicità<br>dei processi di spedizione.
            </div>   
            <div style="margin-top: 30px;">
                <button routerLink="/contact" class="button-white">CONTATTACI</button>
            </div>
        </div>
    </div>

    <app-footer></app-footer>
</section>




<section fxHide fxShow.lt-md *ngIf="!menu">

    <div style="position: relative;">
        <owl-carousel-o [options]="{items: 1, dots:false, navigation:false, nav:false, autoplay:true, autoplayTimeout:3000, loop:true}" #owlCar1>
            <ng-container *ngFor="let slide of slidesHomeDesktop">
                <ng-template carouselSlide [id]="slide.id">
                    <div [style.backgroundImage]="'url(' + slide.src + ')'" style="width: 100%; height:100vh; background-size:cover; background-position:center;"></div>
                </ng-template>
            </ng-container>
        </owl-carousel-o>

        <div style="width: 100%; position: absolute; z-index: 9; top: 0px; background-color: rgba(0,0,0,0.3);"> 
            <div (click)="openMenu()" fxLayout="row" fxLayoutAlign="start start" style="cursor: pointer; height: 150px;">
                <i style="color:white; font-size:30px; margin-left:30px; margin-top:30px;" class="fas fa-bars"></i>
            </div>
            <div  fxLayout="column" fxLayoutAlign="space-between center" style="height: calc( 100vh - 200px); width: 100%;">
                <div>
                    <img src="./assets/verrocchi_bianco.svg" width="280px">
                </div>
                
                <div style="padding-bottom:30px">
                    <button routerLink="/about" class="button-white">ABOUT US</button>
                </div>
                
            </div>
            <div style="height: 50px;">
                <div class="owl-carousel owl-theme owl-loaded">
                    <div class="owl-dots">
                        <div class="owl-dot active"><span></span></div>
                        <div class="owl-dot"><span></span></div>
                        <div class="owl-dot"><span></span></div>
                    </div>
                </div>
            </div>  
        </div>
    </div>

    <div style="padding-left: 15px; padding-top: 15px; padding-right: 15px;">
        <div style="background-image: url('./assets/images/home/Home_servizio_A_desk.jpg'); background-position: center center; background-size: cover;">
            <div style="height: 250px;">

            </div>
            <div fxLayout="column" fxLayoutAlign="center center" style="padding-left: 30px; padding-right: 30px; background-color: rgba(230, 10, 0, 0.9); padding-top: 20px; padding-bottom: 20px;">
                <div style="text-align: center;">
                    <img src="./assets/images/01shipping.svg" width="60px">
                </div>
                <div class="font-40-700 color-white" style="font-size: 30px; line-height: 30px !important; text-align: center; line-height: 40px; margin-top: 30px;">
                    SPEDIZIONI<br>INTERNAZIONALI
                </div>
                <div class="font-16-400 color-white" style="margin-top: 10px; text-align: center;">
                    Con sedi operative a Bari porto, interporto e zona industriale, e una recente sede branch in Albania, a Durazzo e Tirana, offriamo servizi di trasporti stradali, interzonali, marittimi e aerei.
                </div>
                <div style="margin-top: 30px;">
                    <button routerLink="/spedizioni_internazionali" class="button-white" style="text-transform: uppercase;">Scopri di più</button>
                </div>
            </div>
        </div>
    </div>

    <div style="padding-left: 15px; padding-top: 15px; padding-right: 15px;">
        <div style="background-image: url('./assets/images/home/Home_servizio_B_desk.jpg'); background-position: center; background-size: cover; ">
            <div style="height: 250px">

            </div>
            <div fxLayout="column" fxLayoutAlign="center center" style="padding-left: 30px; padding-right: 30px; background-color: rgba(0, 97, 195, 0.9); padding-top: 20px; padding-bottom: 20px;">
                <div style="text-align: center;">
                    <img src="./assets/images/02crane.svg" width="60px">
                </div>
                <div class="font-40-700 color-white" style="font-size: 30px; line-height: 30px !important; text-align: center; line-height: 40px; margin-top: 30px;">
                    DOGANA
                </div>
                <div class="font-16-400 color-white" style="margin-top: 10px; text-align: center;">
                    Da oltre 40 anni, fiore all’occhiello della nostra attività, le operazioni doganali sono il nostro pane quotidiano, e il punto cruciale di ogni spedizione che coinvolga uno o più paesi comunitari ed extra-ue.
                </div>
                <div style="margin-top: 30px;">
                    <button routerLink="/dogana" class="button-white" style="text-transform: uppercase;">Scopri di più</button>
                </div>
            </div>
        </div>
    </div>

    <div style="padding-left: 15px; padding-top: 15px; padding-right: 15px; padding-bottom: 15px;">
        <div style="background-image: url('./assets/images/home/Home_servizio_C_desk.jpg'); background-position: center; background-size: cover;">
            <div style="height: 250px">

            </div>
            <div fxLayout="column" fxLayoutAlign="center center" style="padding-left: 30px; padding-right: 30px; background-color: rgba(45, 195, 0, 0.9); padding-top: 20px; padding-bottom: 20px;">
                <div style="text-align: center;">
                    <img src="./assets/images/03tracking.svg" width="60px">
                </div>
                <div class="font-40-700 color-white" style="font-size: 30px; line-height: 30px !important; text-align: center; line-height: 40px; margin-top: 30px;">
                    LOGISTICA
                </div>
                <div class="font-16-400 color-white" style="margin-top: 10px; text-align: center;">
                    A fronte di sempre più repentini cambiamenti del mercato e dei mutabili scenari del commercio internazionale, una lucida e reattiva gestione della catena logistica può fare la differenza nelle voci di bilancio aziendale
                </div>
                <div style="margin-top: 30px;">
                    <button routerLink="/logistica" class="button-white" style="text-transform: uppercase;">Scopri di più</button>
                </div>
            </div>
        </div>
    </div>

    <div style="background-image: url('./assets/images/home/Home_contattaci_desk.jpg'); background-position: center; background-size: cover; height: 50vh;">
        <div fxLayout="column" fxLayoutAlign="center center" style="padding-left: 15px; padding-right: 15px; background-color: rgba(0, 0, 0, 0.4); height: 50vh;">
            <div class="font-40-700 color-white" style="font-size: 30px; line-height: 30px !important; text-align: center; line-height: 40px; margin-top: 30px;">
                Flessibilità, praticità e semplicità<br>dei processi di spedizione.
            </div>   
            <div style="margin-top: 30px;">
                <button routerLink="/contact" class="button-white">CONTATTACI</button>
            </div>
        </div>
    </div>

    <app-footer></app-footer>
</section>


<div fxLayout="column" fxLayoutAlign="start center" *ngIf="menu" style="padding-bottom: 60px; width: 100%; height: 100vh; overflow-y: scroll; background-color: #0086D4;">
    <div style="padding-top: 30px; padding-bottom: 30px;">
        <img src="./assets/verrocchi_bianco.svg" width="200px">
    </div>
    <div (click)="openMenu()" style="cursor: pointer; position: absolute; right:20px; top:20px;">
        <i style="color:white; font-size:30px" class="fas fa-times"></i>
    </div>

    <div style="width: 100%; height: 1px; background-color:#0070B1;"></div>

    <div style="width: 100%;">
        <button (click)="openLink('home')" class="button-menu-mobile">HOME</button>
    </div>

    <div style="width: 100%; height: 1px; background-color:#0070B1;"></div>

    <div style="width: 100%;">
        <button (click)="openLink('spedizioni_internazionali')" class="button-menu-mobile">SPEDIZIONI INTERNAZIONALI</button>
    </div>

    <div style="width: 100%; height: 1px; background-color:#0070B1;"></div>

    <div style="width: 100%;">
        <button (click)="openLink('dogana')" class="button-menu-mobile">DOGANA</button>
    </div>

    <div style="width: 100%; height: 1px; background-color:#0070B1;"></div>

    <div style="width: 100%;">
        <button (click)="openLink('logistica')" class="button-menu-mobile">LOGISTICA</button>
    </div>

    <div style="width: 100%; height: 1px; background-color:#0070B1;"></div>

    <div style="width: 100%;">
        <button (click)="openLink('about')" class="button-menu-mobile">ABOUT US</button>
    </div>

    <div style="width: 100%; height: 1px; background-color:#0070B1;"></div>

    <div style="width: 100%;">
        <button (click)="openLink('contact')" class="button-menu-mobile">CONTATTI</button>
    </div>

    <div style="width: 100%; height: 1px; background-color:#0070B1;"></div>

    <div style="margin-top: 30px;">
        <i style="font-size: 40px; color:white; margin-right: 15px;" class="fab fa-facebook-square"></i>

        <i style="font-size: 40px; color:white;" class="fab fa-linkedin"></i>
    </div>
</div>