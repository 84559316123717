import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

@Component({
  selector: 'app-service1',
  templateUrl: './service1.component.html',
  styleUrls: ['./service1.component.scss']
})
export class Service1Component implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
    window.scroll(0,0);
  }

  public menu = false;
  openMenu(){
    if(this.menu){
      this.menu = false;
    }else{
      this.menu = true;
    }
  }

  openLink(link){
    this.menu = false;
    this.router.navigate([link]);
  }
}
