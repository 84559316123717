<section fxHide fxShow.md fxShow.gt-md>
    <div style="position: relative;">
        <div style="background-image: url('./assets/images/service/ServizioC_header_desk.jpg'); background-position: center; background-size: cover; height: 40vh;">


        </div>

        <div style="width: 100%; position: absolute; z-index: 9; top: 0px;"> 
            <div fxLayout="row" fxLayoutAlign="space-between center" style="height: 150px;">
                <div style="padding-left: 60px;">
                    <img src="./assets/verrocchi_bianco.svg" width="150px">
                </div>
                <div fxLayout="row" fxLayoutAlign="end center" style="padding-right: 60px;">
                    <div>
                        <button routerLink="/home" class="button-menu">HOME</button>
                    </div>
                    <div>
                        <button routerLink="/spedizioni_internazionali" class="button-menu">SPEDIZIONI INTERNAZIONALI</button>
                    </div>
                    <div>
                        <button routerLink="/dogana" class="button-menu">DOGANA</button>
                    </div>
                    <div>
                        <button routerLink="/logistica" style="border-bottom: 2px solid white;" class="button-menu">LOGISTICA</button>
                    </div>
                    <div>
                        <button routerLink="/about" class="button-menu">ABOUT US</button>
                    </div>
                    <div>
                        <button routerLink="/contact" class="button-menu">CONTATTI</button>
                    </div>
                </div>
            </div>
            <div fxLayout="column" fxLayoutAlign="center center" style="height: calc( 40vh - 150px); width: 100%;">
                <div style="text-align: center;">
                    <img src="./assets/images/03tracking.svg" width="80px">
                </div>
                <div style="margin-top: 15px; text-transform: uppercase;" class="font-34-700 color-white">
                    LOGISTICA
                </div>
            </div> 
        </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="start start" style="padding-left: 120px; padding-top: 60px; padding-right: 120px; padding-bottom: 60px;">
        <div fxFlex="50" style="padding-right: 15px;">
            <div class="font-24-700 color-green">
                Ottimizzare tempo e risorse economiche.
            </div>
            <div class="font-18-300">
                A fronte di sempre più repentini cambiamenti del mercato e dei mutabili scenari del commercio internazionale, una lucida e reattiva gestione della catena logistica può fare la differenza nelle voci di bilancio aziendale, e può permettere al cliente di competere al meglio nel proprio settore svolgendo le attività di ricezione e consegna merci in un’ottica di ottimizzazione di tempo e risorse economiche.
                Sviluppiamo assieme al cliente, scenari e progetti logistici anche su vasta scala, e per varie tipologie di merci: food&beverage, fashion, abbigliamento, manifattura industriale, automotive components, materie prime, imballaggi, pallets, ecc..
            </div>
        </div>
        <div fxFlex="50" style="padding-left: 15px;">
            <img src="./assets/images/service/logistica_1.jpg" width="100%"> 
        </div>
    </div>


    <div fxLayout="row" fxLayoutAlign="start start" style="background-color: #F7F7F7; padding-left: 120px; padding-top: 60px; padding-right: 120px; padding-bottom: 60px;">
        <div fxFlex="50" style="padding-right: 15px;">
            <div fxLayout="row" fxLayoutAlign="start center">
                <div class="font-24-700 color-green">Stoccaggio e distribuzione</div>
            </div>
            <div class="font-18-300">
                Disponiamo di spazi coperti e scoperti nella zona industriale di Bari, con diverse capacità di stoccaggio, anche a temperatura controllata, con personale altamente qualificato, tecnologie all’avanguardia, monitoraggio dei flussi di merci costantemente reperibili e agevolmente consultabili, vigilanza H 24; grazie al nostro network di partner e fornitori offriamo tali servizi anche su tutto il territorio nazionale, con particolare concentrazione nei punti di maggiore richiesta (porti, interporti) e con l’apporto di servizi ad alto valore aggiunto (magazzino doganale, sospensione IVA e procedura semplificata di sdoganamento, gestione dei certificati di analisi, etichettature e riettichettature di sicurezza e commerciali, confezionamenti/riconfezionamenti, ecc).
                <br><br>
                Con la strettissima collaborazione di vettori stradali ed intermodali, possiamo garantire ritiri e consegne settimanali di merci pallettizzate, su tutte le direttrici nazionali Nord-Sud-Isole e viceversa, usufruendo dello stoccaggio anche di breve durata (cross docking) prima della consegna richiesta. Permettiamo così di poter sviluppare i propri business aziendali in aree lontane da quelle di produzione, e dove richiesto dal mercato, in maniera più intelligente, efficiente ed economica, occupandoci anche della fase distributiva.
            </div>
        </div>
        <div fxFlex="50" style="padding-left: 15px;">
            <img src="./assets/images/service/logistica_2.jpg" width="100%"> 
        </div>
    </div>

    <div style="background-image: url('./assets/images/service/Box_contattaci_servizio_desk.jpg'); background-position: center; background-size: cover; height: 50vh;">
        <div fxLayout="column" fxLayoutAlign="center center" style="padding-left: 30px; padding-right: 30px; background-color: rgba(0, 0, 0, 0.4); height: 50vh;">
            <div class="font-40-700 color-white" style="text-align: center; line-height: 40px; margin-top: 30px;">
                Flessibilità, praticità e semplicità<br>dei processi di spedizione.
            </div>   
            <div style="margin-top: 30px;">
                <button routerLink="/contact" class="button-white">CONTATTACI</button>
            </div>
        </div>
    </div>

    

    <div fxLayout="row" fxLayoutAlign="center start" style="margin-top: 60px; margin-bottom: 60px;">
        <div routerLink="/spedizioni_internazionali" style="cursor: pointer; padding-left: 50px; padding-right: 50px; width: 250px; height: 350px; padding-top: 30px; padding-bottom: 30px; margin-right: 5px; margin-left: 5px; text-align: center; background-color: #C30000;">
            <img src="./assets/images/01shipping.svg" width="100px">
            <div class="font-24-700 color-white" style="margin-top: 10px; line-height: 25px;">
                Spedizioni
                <br>internazionali
            </div>
            <div class="font-18-300 color-white" style="margin-top: 10px;">
                Con sedi operative a Bari porto, interporto e zona industriale, e una recente sede branch in Albania, a Durazzo e Tirana, offriamo servizi di trasporti stradali, interzonali, marittimi e aerei.
            </div>
        </div>
        <div routerLink="/dogana" style="cursor: pointer; padding-left: 50px; padding-right: 50px; width: 250px; height: 350px; padding-top: 30px; padding-bottom: 30px; margin-right: 5px; margin-left: 5px; text-align: center; background-color: #0061C3;">
            <img src="./assets/images/02crane.svg" width="100px">
            <div class="font-24-700 color-white" style="margin-top: 10px; line-height: 25px;">
                Dogana
            </div>
            <div class="font-18-300 color-white" style="margin-top: 10px;">
                Da oltre 40 anni, fiore all’occhiello della nostra attività, le operazioni doganali sono il nostro pane quotidiano, e il punto cruciale di ogni spedizione che coinvolga uno o più paesi comunitari ed extra-ue.
            </div>
        </div>
    </div>

    <app-footer></app-footer>
</section>


<section *ngIf="!menu" fxHide fxShow.lt-md>
    
    <div style="position: relative;">
        <div style="background-image: url('./assets/images/service/ServizioC_header_desk.jpg'); background-position: center; background-size: cover; height: 40vh;">


        </div>

        <div style="width: 100%; position: absolute; z-index: 9; top: 0px;"> 
            <div fxLayout="row" fxLayoutAlign="start center" style="height: 150px;">
                <div (click)="openMenu()" fxFlex="20" fxLayout="row" fxLayoutAlign="start start" style="cursor: pointer; height: 150px;">
                    <i style="color:white; font-size:30px; margin-left:30px; margin-top:30px;" class="fas fa-bars"></i>
                </div>
                <div fxFlex="60" style="text-align: center;">
                    <img src="./assets/verrocchi_bianco.svg" width="150px">
                </div>
                <div fxFlex="20"></div>
            </div>
            <div fxLayout="row" fxLayoutAlign="start end" style="padding-bottom: 20px; height: calc( 40vh - 150px); width: 100%;">
                <div style="text-align: center; padding-left: 25px; padding-right: 10px;">
                    <img src="./assets/images/03tracking.svg" width="50px">
                </div>
                <div style="padding-right: 25px; text-transform: uppercase; font-size: 26px; line-height: 24px;" class="font-34-700 color-white">
                    LOGISTICA
                </div>
            </div> 
        </div>
    </div>

    <div style="padding-left: 25px; padding-top: 40px; padding-right: 25px; padding-bottom: 40px;">
        <div>
            <div class="font-24-700 color-green">
                Ottimizzare tempo e risorse economiche.
            </div>
            <div class="font-16-300">
                A fronte di sempre più repentini cambiamenti del mercato e dei mutabili scenari del commercio internazionale, una lucida e reattiva gestione della catena logistica può fare la differenza nelle voci di bilancio aziendale, e può permettere al cliente di competere al meglio nel proprio settore svolgendo le attività di ricezione e consegna merci in un’ottica di ottimizzazione di tempo e risorse economiche.
                Sviluppiamo assieme al cliente, scenari e progetti logistici anche su vasta scala, e per varie tipologie di merci: food&beverage, fashion, abbigliamento, manifattura industriale, automotive components, materie prime, imballaggi, pallets, ecc..
            </div>
        </div>
        <div style="padding-top: 25px;">
            <img src="./assets/images/service/logistica_1.jpg" width="100%"> 
        </div>
    </div>


    <div style="background-color: #F7F7F7; padding-left: 25px; padding-top: 40px; padding-right: 25px; padding-bottom: 40px;">
        <div style="padding-right: 15px;">
            <div fxLayout="row" fxLayoutAlign="start center">
                <div class="font-24-700 color-green">Stoccaggio e distribuzione</div>
            </div>
            <div class="font-16-300">
                Disponiamo di spazi coperti e scoperti nella zona industriale di Bari, con diverse capacità di stoccaggio, anche a temperatura controllata, con personale altamente qualificato, tecnologie all’avanguardia, monitoraggio dei flussi di merci costantemente reperibili e agevolmente consultabili, vigilanza H 24; grazie al nostro network di partner e fornitori offriamo tali servizi anche su tutto il territorio nazionale, con particolare concentrazione nei punti di maggiore richiesta (porti, interporti) e con l’apporto di servizi ad alto valore aggiunto (magazzino doganale, sospensione IVA e procedura semplificata di sdoganamento, gestione dei certificati di analisi, etichettature e riettichettature di sicurezza e commerciali, confezionamenti/riconfezionamenti, ecc).
                <br><br>
                Con la strettissima collaborazione di vettori stradali ed intermodali, possiamo garantire ritiri e consegne settimanali di merci pallettizzate, su tutte le direttrici nazionali Nord-Sud-Isole e viceversa, usufruendo dello stoccaggio anche di breve durata (cross docking) prima della consegna richiesta. Permettiamo così di poter sviluppare i propri business aziendali in aree lontane da quelle di produzione, e dove richiesto dal mercato, in maniera più intelligente, efficiente ed economica, occupandoci anche della fase distributiva.
            </div>
        </div>
        <div style="padding-top: 25px;">
            <img src="./assets/images/service/logistica_2.jpg" width="100%"> 
        </div>
    </div>

    <div style="background-image: url('./assets/images/home/Home_contattaci_desk.jpg'); background-position: center; background-size: cover; height: 50vh;">
        <div fxLayout="column" fxLayoutAlign="center center" style="padding-left: 15px; padding-right: 15px; background-color: rgba(0, 0, 0, 0.4); height: 50vh;">
            <div class="font-40-700 color-white" style="font-size: 30px; line-height: 30px !important; text-align: center; line-height: 40px; margin-top: 30px;">
                Flessibilità, praticità e semplicità<br>dei processi di spedizione.
            </div>   
            <div style="margin-top: 30px;">
                <button routerLink="/contact" class="button-white">CONTATTACI</button>
            </div>
        </div>
    </div>

    <div fxLayout="column" fxLayoutAlign="center center" style="margin-top: 40px; margin-bottom: 40px;">
        <div routerLink="/spedizioni_internazionali" style="cursor: pointer; padding-left: 50px; padding-right: 50px; width: 250px; height: 350px; padding-top: 30px; padding-bottom: 30px; margin-right: 5px; margin-left: 5px; text-align: center; background-color: #C30000;">
            <img src="./assets/images/01shipping.svg" width="60px">
            <div class="font-24-700 color-white" style="margin-top: 10px; line-height: 25px;">
                Spedizioni
                <br>internazionali
            </div>
            <div class="font-18-300 color-white" style="margin-top: 10px;">
                Con sedi operative a Bari porto, interporto e zona industriale, e una recente sede branch in Albania, a Durazzo e Tirana, offriamo servizi di trasporti stradali, interzonali, marittimi e aerei.
            </div>
        </div>
        <div routerLink="/dogana" style="margin-top: 40px; cursor: pointer; padding-left: 50px; padding-right: 50px; width: 250px; height: 350px; padding-top: 30px; padding-bottom: 30px; margin-right: 5px; margin-left: 5px; text-align: center; background-color: #0061C3;">
            <img src="./assets/images/02crane.svg" width="60px">
            <div class="font-24-700 color-white" style="margin-top: 10px; line-height: 25px;">
                Dogana
            </div>
            <div class="font-18-300 color-white" style="margin-top: 10px;">
                Da oltre 40 anni, fiore all’occhiello della nostra attività, le operazioni doganali sono il nostro pane quotidiano, e il punto cruciale di ogni spedizione che coinvolga uno o più paesi comunitari ed extra-ue.
            </div>
        </div>
    </div>

    <app-footer></app-footer>
</section>



<div fxLayout="column" fxLayoutAlign="start center" *ngIf="menu" style="padding-bottom: 60px; width: 100%; height: 100vh; overflow-y: scroll; background-color: #0086D4;">
    <div style="padding-top: 30px; padding-bottom: 30px;">
        <img src="./assets/verrocchi_bianco.svg" width="200px">
    </div>
    <div (click)="openMenu()" style="cursor: pointer; position: absolute; right:20px; top:20px;">
        <i style="color:white; font-size:30px" class="fas fa-times"></i>
    </div>

    <div style="width: 100%; height: 1px; background-color:#0070B1;"></div>

    <div style="width: 100%;">
        <button (click)="openLink('home')" class="button-menu-mobile">HOME</button>
    </div>

    <div style="width: 100%; height: 1px; background-color:#0070B1;"></div>

    <div style="width: 100%;">
        <button (click)="openLink('spedizioni_internazionali')" class="button-menu-mobile">SPEDIZIONI INTERNAZIONALI</button>
    </div>

    <div style="width: 100%; height: 1px; background-color:#0070B1;"></div>

    <div style="width: 100%;">
        <button (click)="openLink('dogana')" class="button-menu-mobile">DOGANA</button>
    </div>

    <div style="width: 100%; height: 1px; background-color:#0070B1;"></div>

    <div style="width: 100%;">
        <button (click)="openLink('logistica')" class="button-menu-mobile">LOGISTICA</button>
    </div>

    <div style="width: 100%; height: 1px; background-color:#0070B1;"></div>

    <div style="width: 100%;">
        <button (click)="openLink('about')" class="button-menu-mobile">ABOUT US</button>
    </div>

    <div style="width: 100%; height: 1px; background-color:#0070B1;"></div>

    <div style="width: 100%;">
        <button (click)="openLink('contact')" class="button-menu-mobile">CONTATTI</button>
    </div>

    <div style="width: 100%; height: 1px; background-color:#0070B1;"></div>

    <div style="margin-top: 30px;">
        <i style="font-size: 40px; color:white; margin-right: 15px;" class="fab fa-facebook-square"></i>

        <i style="font-size: 40px; color:white;" class="fab fa-linkedin"></i>
    </div>
</div>