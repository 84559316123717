import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './pages/home/home.component';
import { AboutComponent } from './pages/about/about.component';
import { ContactComponent } from './pages/contact/contact.component';
import { Service1Component } from './pages/service1/service1.component';
import { Service2Component } from './pages/service2/service2.component';
import { Service3Component } from './pages/service3/service3.component';

const routes: Routes = [
  {
    path: '',
    redirectTo:'landing',
    pathMatch: 'full'
  },
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'about',
    component: AboutComponent,
  },
  {
    path: 'contact',
    component: ContactComponent,
  },
  {
    path: 'service1',
    component: Service1Component,
  },
  {
    path: 'service2',
    component: Service2Component,
  },
  {
    path: 'service3',
    component: Service3Component,
  },
  {
    path: 'spedizioni_internazionali',
    component: Service1Component,
  },
  {
    path: 'dogana',
    component: Service2Component,
  },
  {
    path: 'logistica',
    component: Service3Component,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
