<section fxHide fxShow.md fxShow.gt-md>
    <div style="position: relative;">
        <div style="background-image: url('./assets/images/service/ServizioB_header_desk.jpg'); background-position: center; background-size: cover; height: 40vh;">


        </div>

        <div style="width: 100%; position: absolute; z-index: 9; top: 0px;"> 
            <div fxLayout="row" fxLayoutAlign="space-between center" style="height: 150px;">
                <div style="padding-left: 60px;">
                    <img src="./assets/verrocchi_bianco.svg" width="150px">
                </div>
                <div fxLayout="row" fxLayoutAlign="end center" style="padding-right: 60px;">
                    <div>
                        <button routerLink="/home" class="button-menu">HOME</button>
                    </div>
                    <div>
                        <button routerLink="/spedizioni_internazionali" class="button-menu">SPEDIZIONI INTERNAZIONALI</button>
                    </div>
                    <div>
                        <button routerLink="/dogana" style="border-bottom: 2px solid white;" class="button-menu">DOGANA</button>
                    </div>
                    <div>
                        <button routerLink="/logistica" class="button-menu">LOGISTICA</button>
                    </div>
                    <div>
                        <button routerLink="/about" class="button-menu">ABOUT US</button>
                    </div>
                    <div>
                        <button routerLink="/contact" class="button-menu">CONTATTI</button>
                    </div>
                </div>
            </div>
            <div fxLayout="column" fxLayoutAlign="center center" style="height: calc( 40vh - 150px); width: 100%;">
                <div style="text-align: center;">
                    <img src="./assets/images/02crane.svg" width="80px">
                </div>
                <div style="margin-top: 15px; text-transform: uppercase;" class="font-34-700 color-white">
                    Dogana
                </div>
            </div> 
        </div>
    </div>

    <div style="padding-left: 120px; padding-top: 60px; padding-right: 120px; padding-bottom: 60px;">
        <div class="font-24-700 color-blue">
            Operazioni doganali, il nostro fiore all’occhiello.
        </div>
        <div class="font-18-300">
            Da oltre 40 anni, fiore all’occhiello della nostra attività, le operazioni doganali sono il nostro pane quotidiano, e il punto cruciale di ogni spedizione che coinvolga uno o più paesi comunitari ed extra-ue. Tra i nostri collaboratori, personale alle dirette dipendenze e coadiutori fidati su tutto il territorio nazionale, molti sono in possesso del patentino professionale di spedizioniere doganale, regolarmente iscritti all’albo ed in continua formazione.
            <br>L’attenzione profonda e meticolosa dedita in questo settore ha fatto si che durante gli anni si siano sviluppate competenze e padronanza delle procedure di ogni sorta, in tutti gli ambiti e per tutte le tipologie di spedizioni (terrestri, aeree, marittime).
            <br>Garantiamo sdoganamenti nei porti, aeroporti, interporti e zone franche nazionali, e nei principali hub europei.
            <br>Le formalità espletate sono molteplici:
            <br>- bollette doganali di importazione ed esportazione definitive e temporanee
            <br>- bollette di transito comunitario esterno ed interno (T1, T2)
            <br>- bollette T2L, T2LF
            <br>- richiesta ed emissione certificati di circolazione EUR1, ATR
            <br>- deposito IVA, deposito doganale, temporanea custodia
            <br>- richiesta ed emissione nulla osta Sanitario, Veterinario, Fitopatologo
            <br>- richiesta certificati e autorizzazioni ministeriali (Documento di Vigilanza Siderurgica, FLEGT, CITES)
            <br>- richiesta controllo e certificato di Radiometria
            <br>- gestione e trasmissione INTRA

        </div>
    </div>

    <div style="padding-left: 120px; padding-right: 120px;">
        <img src="./assets/images/service/dogana.jpg" width="100%"> 
    </div>

    <div style="background-color: #F7F7F7; margin-top: 60px; padding-left: 120px; padding-top: 60px; padding-right: 120px; padding-bottom: 60px;">
        <div class="font-24-700 color-blue">
            Servizi di consulenza
        </div>
        <div class="font-18-300">
            In virtù della maturata esperienza e dell’approfondita conoscenza della normativa doganale e del commercio internazionale , offriamo alla clientela servizi di consulenza ed assistenza dedicati in materia di:
            <br>- traffico di perfezionamento passivo ed attivo (TPP, TPA)
            <br>- gestione istanze di richiesta ITV, IVO, DPO, CGU ecc, attraverso il sistema delle Customs Decisions (EU Trader Portal);
            <br>- classificazione doganale delle varie tipologie merceologiche
            <br>- informative sulle principali novità in materia doganale
            <br>- rappresentanza fiscale leggera
            <br>- formazione e consulenza ai fini del rilascio delle Certificazioni AEO
            <br>- valutazione delle agevolazioni tariffarie doganali
            <br>- analisi delle restrizioni alla importazione ed alla esportazione
            <br>- assistenza al contenzioso doganale
            <br>- assistenza tributaria in materia di scambi intracomunitari
            <br>- valutazione dei rischi ed assicurazione delle merci
            <br>- consulenza su Incoterms e crediti documentari
            <br>Inoltre, collaborando da sempre con le principali agenzie marittime nei maggiori porti nazionali, offriamo supporto nei servizi di brokeraggio e raccomandazione marittima per navi cargo e navi di linea RO-PAX, RO-RO, bulk e break bulk, yatch e crociere, provvedendo quindi all’espletamento delle correlate formalità doganali come manifesti di carico merci in arrivo e partenza (MMA, MMP), sdoganamento merci di provveditoria marittima ed aerea, e molto altro ancora.

        </div>
        <div style="margin-top: 60px;">
            <img src="./assets/images/service/ServizioB_foto_02_desk.jpg" width="100%"> 
        </div>
    </div>

    <div style="background-image: url('./assets/images/service/Box_contattaci_servizio_desk.jpg'); background-position: center; background-size: cover; height: 50vh;">
        <div fxLayout="column" fxLayoutAlign="center center" style="padding-left: 30px; padding-right: 30px; background-color: rgba(0, 0, 0, 0.4); height: 50vh;">
            <div class="font-40-700 color-white" style="text-align: center; line-height: 40px; margin-top: 30px;">
                Flessibilità, praticità e semplicità<br>dei processi di spedizione.
            </div>   
            <div style="margin-top: 30px;">
                <button routerLink="/contact" class="button-white">CONTATTACI</button>
            </div>
        </div>
    </div>

    

    <div fxLayout="row" fxLayoutAlign="center start" style="margin-top: 60px; margin-bottom: 60px;">
        <div routerLink="/spedizioni_internazionali" style="cursor: pointer; padding-left: 50px; padding-right: 50px; width: 250px; height: 350px; padding-top: 30px; padding-bottom: 30px; margin-right: 5px; margin-left: 5px; text-align: center; background-color: #C30000;">
            <img src="./assets/images/01shipping.svg" width="100px">
            <div class="font-24-700 color-white" style="margin-top: 10px; line-height: 25px;">
                Spedizioni
                <br>internazionali
            </div>
            <div class="font-18-300 color-white" style="margin-top: 10px;">
                Con sedi operative a Bari porto, interporto e zona industriale, e una recente sede branch in Albania, a Durazzo e Tirana, offriamo servizi di trasporti stradali, interzonali, marittimi e aerei.
            </div>
        </div>
        <div routerLink="/logistica" style="cursor: pointer; padding-left: 50px; padding-right: 50px; width: 250px; height: 350px; padding-top: 30px; padding-bottom: 30px; margin-right: 5px; margin-left: 5px; text-align: center; background-color: #2DC300;">
            <img src="./assets/images/03tracking.svg" width="100px">
            <div class="font-24-700 color-white" style="margin-top: 10px; line-height: 25px;">
                Logistica
            </div>
            <div class="font-18-300 color-white" style="margin-top: 10px;">
                A fronte di sempre più repentini cambiamenti del mercato e dei mutabili scenari del commercio internazionale, una lucida e reattiva gestione della catena logistica può fare la differenza nelle voci di bilancio aziendale
            </div>
        </div>
    </div>

    <app-footer></app-footer>
</section>






<section *ngIf="!menu" fxHide fxShow.lt-md>
    <div style="position: relative;">
        <div style="background-image: url('./assets/images/service/ServizioB_header_desk.jpg'); background-position: center; background-size: cover; height: 40vh;">


        </div>

        <div style="width: 100%; position: absolute; z-index: 9; top: 0px;"> 
            <div fxLayout="row" fxLayoutAlign="start center" style="height: 150px;">
                <div (click)="openMenu()" fxFlex="20" fxLayout="row" fxLayoutAlign="start start" style="cursor: pointer; height: 150px;">
                    <i style="color:white; font-size:30px; margin-left:30px; margin-top:30px;" class="fas fa-bars"></i>
                </div>
                <div fxFlex="60" style="text-align: center;">
                    <img src="./assets/verrocchi_bianco.svg" width="150px">
                </div>
                <div fxFlex="20"></div>
            </div>
            <div fxLayout="row" fxLayoutAlign="start end" style="padding-bottom: 20px; height: calc( 40vh - 150px); width: 100%;">
                <div style="text-align: center; padding-left: 25px; padding-right: 10px;">
                    <img src="./assets/images/02crane.svg" width="50px">
                </div>
                <div style="padding-right: 25px; text-transform: uppercase; font-size: 26px; line-height: 24px;" class="font-34-700 color-white">
                   DOGANA
                </div>
            </div> 
        </div>
    </div>

    <div style="padding-left: 25px; padding-top: 40px; padding-right: 25px; padding-bottom: 40px;">
        <div class="font-24-700 color-blue">
            Operazioni doganali, il nostro fiore all’occhiello.
        </div>
        <div class="font-16-300">
            Da oltre 40 anni, fiore all’occhiello della nostra attività, le operazioni doganali sono il nostro pane quotidiano, e il punto cruciale di ogni spedizione che coinvolga uno o più paesi comunitari ed extra-ue. Tra i nostri collaboratori, personale alle dirette dipendenze e coadiutori fidati su tutto il territorio nazionale, molti sono in possesso del patentino professionale di spedizioniere doganale, regolarmente iscritti all’albo ed in continua formazione.
            <br>L’attenzione profonda e meticolosa dedita in questo settore ha fatto si che durante gli anni si siano sviluppate competenze e padronanza delle procedure di ogni sorta, in tutti gli ambiti e per tutte le tipologie di spedizioni (terrestri, aeree, marittime).
            <br>Garantiamo sdoganamenti nei porti, aeroporti, interporti e zone franche nazionali, e nei principali hub europei.
            <br>Le formalità espletate sono molteplici:
            <br>- bollette doganali di importazione ed esportazione definitive e temporanee
            <br>- bollette di transito comunitario esterno ed interno (T1, T2)
            <br>- bollette T2L, T2LF
            <br>- richiesta ed emissione certificati di circolazione EUR1, ATR
            <br>- deposito IVA, deposito doganale, temporanea custodia
            <br>- richiesta ed emissione nulla osta Sanitario, Veterinario, Fitopatologo
            <br>- richiesta certificati e autorizzazioni ministeriali (Documento di Vigilanza Siderurgica, FLEGT, CITES)
            <br>- richiesta controllo e certificato di Radiometria
            <br>- gestione e trasmissione INTRA

        </div>
    </div>

    <div style="padding-left: 25px; padding-right: 25px;">
        <img src="./assets/images/service/dogana.jpg" width="100%"> 
    </div>

    <div style="background-color: #F7F7F7; margin-top: 40px; padding-left: 25px; padding-top: 40px; padding-right: 25px; padding-bottom: 40px;">
        <div class="font-24-700 color-blue">
            Servizi di consulenza
        </div>
        <div class="font-16-300">
            In virtù della maturata esperienza e dell’approfondita conoscenza della normativa doganale e del commercio internazionale , offriamo alla clientela servizi di consulenza ed assistenza dedicati in materia di:
            <br>- traffico di perfezionamento passivo ed attivo (TPP, TPA)
            <br>- gestione istanze di richiesta ITV, IVO, DPO, CGU ecc, attraverso il sistema delle Customs Decisions (EU Trader Portal);
            <br>- classificazione doganale delle varie tipologie merceologiche
            <br>- informative sulle principali novità in materia doganale
            <br>- rappresentanza fiscale leggera
            <br>- formazione e consulenza ai fini del rilascio delle Certificazioni AEO
            <br>- valutazione delle agevolazioni tariffarie doganali
            <br>- analisi delle restrizioni alla importazione ed alla esportazione
            <br>- assistenza al contenzioso doganale
            <br>- assistenza tributaria in materia di scambi intracomunitari
            <br>- valutazione dei rischi ed assicurazione delle merci
            <br>- consulenza su Incoterms e crediti documentari
            <br>Inoltre, collaborando da sempre con le principali agenzie marittime nei maggiori porti nazionali, offriamo supporto nei servizi di brokeraggio e raccomandazione marittima per navi cargo e navi di linea RO-PAX, RO-RO, bulk e break bulk, yatch e crociere, provvedendo quindi all’espletamento delle correlate formalità doganali come manifesti di carico merci in arrivo e partenza (MMA, MMP), sdoganamento merci di provveditoria marittima ed aerea, e molto altro ancora.

        </div>
        <div style="margin-top: 60px;">
            <img src="./assets/images/service/ServizioB_foto_02_desk.jpg" width="100%"> 
        </div>
    </div>

    <div style="background-image: url('./assets/images/home/Home_contattaci_desk.jpg'); background-position: center; background-size: cover; height: 50vh;">
        <div fxLayout="column" fxLayoutAlign="center center" style="padding-left: 15px; padding-right: 15px; background-color: rgba(0, 0, 0, 0.4); height: 50vh;">
            <div class="font-40-700 color-white" style="font-size: 30px; line-height: 30px !important; text-align: center; line-height: 40px; margin-top: 30px;">
                Flessibilità, praticità e semplicità<br>dei processi di spedizione.
            </div>   
            <div style="margin-top: 30px;">
                <button routerLink="/contact" class="button-white">CONTATTACI</button>
            </div>
        </div>
    </div>
    

    <div fxLayout="column" fxLayoutAlign="center center" style="margin-top: 40px; margin-bottom: 40px;">
        <div routerLink="/spedizioni_internazionali" style="cursor: pointer; padding-left: 50px; padding-right: 50px; width: 250px; height: 350px; padding-top: 30px; padding-bottom: 30px; margin-right: 5px; margin-left: 5px; text-align: center; background-color: #C30000;">
            <img src="./assets/images/01shipping.svg" width="60px">
            <div class="font-24-700 color-white" style="margin-top: 10px; line-height: 25px;">
                Spedizioni
                <br>internazionali
            </div>
            <div class="font-18-300 color-white" style="margin-top: 10px;">
                Con sedi operative a Bari porto, interporto e zona industriale, e una recente sede branch in Albania, a Durazzo e Tirana, offriamo servizi di trasporti stradali, interzonali, marittimi e aerei.
            </div>
        </div>
        <div routerLink="/logistica" style="margin-top: 40px; cursor: pointer; padding-left: 50px; padding-right: 50px; width: 250px; height: 350px; padding-top: 30px; padding-bottom: 30px; margin-right: 5px; margin-left: 5px; text-align: center; background-color: #2DC300;">
            <img src="./assets/images/03tracking.svg" width="60px">
            <div class="font-24-700 color-white" style="margin-top: 10px; line-height: 25px;">
                Logistica
            </div>
            <div class="font-18-300 color-white" style="margin-top: 10px;">
                A fronte di sempre più repentini cambiamenti del mercato e dei mutabili scenari del commercio internazionale, una lucida e reattiva gestione della catena logistica può fare la differenza nelle voci di bilancio aziendale
            </div>
        </div>
    </div>

  
    <app-footer></app-footer>
</section>


<div fxLayout="column" fxLayoutAlign="start center" *ngIf="menu" style="padding-bottom: 60px; width: 100%; height: 100vh; overflow-y: scroll; background-color: #0086D4;">
    <div style="padding-top: 30px; padding-bottom: 30px;">
        <img src="./assets/verrocchi_bianco.svg" width="200px">
    </div>
    <div (click)="openMenu()" style="cursor: pointer; position: absolute; right:20px; top:20px;">
        <i style="color:white; font-size:30px" class="fas fa-times"></i>
    </div>

    <div style="width: 100%; height: 1px; background-color:#0070B1;"></div>

    <div style="width: 100%;">
        <button (click)="openLink('home')" class="button-menu-mobile">HOME</button>
    </div>

    <div style="width: 100%; height: 1px; background-color:#0070B1;"></div>

    <div style="width: 100%;">
        <button (click)="openLink('spedizioni_internazionali')" class="button-menu-mobile">SPEDIZIONI INTERNAZIONALI</button>
    </div>

    <div style="width: 100%; height: 1px; background-color:#0070B1;"></div>

    <div style="width: 100%;">
        <button (click)="openLink('dogana')" class="button-menu-mobile">DOGANA</button>
    </div>

    <div style="width: 100%; height: 1px; background-color:#0070B1;"></div>

    <div style="width: 100%;">
        <button (click)="openLink('logistica')" class="button-menu-mobile">LOGISTICA</button>
    </div>

    <div style="width: 100%; height: 1px; background-color:#0070B1;"></div>

    <div style="width: 100%;">
        <button (click)="openLink('about')" class="button-menu-mobile">ABOUT US</button>
    </div>

    <div style="width: 100%; height: 1px; background-color:#0070B1;"></div>

    <div style="width: 100%;">
        <button (click)="openLink('contact')" class="button-menu-mobile">CONTATTI</button>
    </div>

    <div style="width: 100%; height: 1px; background-color:#0070B1;"></div>

    <div style="margin-top: 30px;">
        <i style="font-size: 40px; color:white; margin-right: 15px;" class="fab fa-facebook-square"></i>

        <i style="font-size: 40px; color:white;" class="fab fa-linkedin"></i>
    </div>
</div>