<section fxHide fxShow.md fxShow.gt-md fxLayout="row" fxLayoutAlign="space-between center" style="background-color: #0080C8; height: 150px; width: 100%;">
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <div style="padding-left: 60px;">
            <img src="./assets/verrocchi_bianco.svg" width="100px">
        </div>
        <div style="padding-left: 60px;">
            <div class="font-14-700 color-white">Verrocchi Group Srl</div>
            <div class="font-14-300 color-white">Via Porto Nuovo, 2 - Scala D - P.1 Int. 32</div>
            <div class="font-14-300 color-white">70122 BARI - Italy - Tel +39 080.5246545</div>
            <div class="font-14-300 color-white">info@verrocchi.com</div>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="end center" style="padding-right: 60px;">
        <div (click)="openLink('https://www.composingstudio.com')" style="cursor: pointer;" class="font-14-300 color-white">a custom project by <span style="font-weight: 700;">composingstudio</span></div>
    </div>
</section>


<section fxHide fxShow.lt-md>
    <div fxLayout="column" fxLayoutAlign="center center" style="padding-top: 20px; background-color: #0080C8; width: 100%;">
        <div>
            <img src="./assets/verrocchi_bianco.svg" width="100px">
        </div>
        <div style="text-align: center; margin-top: 20px; padding-bottom: 20px;">
            <div class="font-14-700 color-white">Verrocchi Group Srl</div>
            <div class="font-14-300 color-white">Via Porto Nuovo, 2 - Scala D - P.1 Int. 32</div>
            <div class="font-14-300 color-white">70122 BARI - Italy - Tel +39 080.5246545</div>
            <div class="font-14-300 color-white">info@verrocchi.com</div>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" style="padding-top: 15px; padding-bottom: 15px; background-color: white;">
        <div (click)="openLink('https://www.composingstudio.com')" style="cursor: pointer;" class="font-14-300 color-black">a custom project by <span style="font-weight: 700;">composingstudio</span></div>
    </div>
</section>