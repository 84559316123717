<section fxHide fxShow.md fxShow.gt-md>
    <div style="position: relative;">
        <div style="background-image: url('./assets/images/contact/Contatti_header_desk.jpg'); background-position: center; background-size: cover; height: 40vh;">


        </div>

        <div style="width: 100%; position: absolute; z-index: 9; top: 0px;"> 
            <div fxLayout="row" fxLayoutAlign="space-between center" style="height: 150px;">
                <div style="padding-left: 60px;">
                    <img src="./assets/verrocchi_bianco.svg" width="150px">
                </div>
                <div fxLayout="row" fxLayoutAlign="end center" style="padding-right: 60px;">
                    <div>
                        <button routerLink="/home" class="button-menu">HOME</button>
                    </div>
                    <div>
                        <button routerLink="/spedizioni_internazionali" class="button-menu">SPEDIZIONI INTERNAZIONALI</button>
                    </div>
                    <div>
                        <button routerLink="/dogana" class="button-menu">DOGANA</button>
                    </div>
                    <div>
                        <button routerLink="/logistica" class="button-menu">LOGISTICA</button>
                    </div>
                    <div>
                        <button routerLink="/about" class="button-menu">ABOUT US</button>
                    </div>
                    <div>
                        <button routerLink="/contact" style="border-bottom: 2px solid white;" class="button-menu">CONTATTI</button>
                    </div>
                </div>
            </div>
            <div fxLayout="column" fxLayoutAlign="center center" style="height: calc( 40vh - 150px); width: 100%;">
                <div style="text-align: center;">
                    <img src="./assets/images/02crane.svg" width="80px">
                </div>
                <div style="margin-top: 15px; text-transform: uppercase;" class="font-34-700 color-white">
                    Contatti
                </div>
            </div> 
        </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="start start"  style="padding-left: 120px; padding-top: 60px; padding-right: 120px; padding-bottom: 60px;">
        <div fxFlex="50" style="padding-right: 30px;">
            <div class="font-24-700 color-blue">
                Verrocchi Group Srl
            </div>
            <div class="font-18-300">
                Via Porto Nuovo, 2 - Scala D - P.1 Int. 32<br>
                70122 BARI - Italy - Tel +39 080.5246545<br>
                info@verrocchi.com
            </div>
            <div class="font-24-700 color-blue" style="margin-top: 120px;">
                Quick Mail
            </div>
            <div fxLayout="column" fxLayoutAlign="start start" >

                <input [(ngModel)]="nome" placeholder="Nome e Cognome" class="input-form" style="margin-top: 30px;">

                <input [(ngModel)]="company" placeholder="Azienda" class="input-form" style="margin-top: 15px;">

                <input [(ngModel)]="email" placeholder="Email" class="input-form" style="margin-top: 15px;">

                <input [(ngModel)]="phone" placeholder="Telefono" class="input-form" style="margin-top: 15px;">

                <textarea [(ngModel)]="message" placeholder="Scrivi messaggio" class="input-form" style="height: 200px; margin-top: 15px;"></textarea>

                <div *ngIf="error" style="background-color: #f05555; color:white; padding-left:10px; padding-top: 5px; padding-bottom: 5px; padding-right: 15px; margin-top:20px; font-size: 14px;">
                    {{error}}
                </div>

                <div *ngIf="success" style="background-color: green; color:white; padding-left:10px; padding-top: 5px; padding-bottom: 5px; padding-right: 15px; margin-top:20px; font-size: 14px;">
                    {{success}}
                </div>

                <div fxLayout="row" fxLayoutAlign="space-between center" >
                    <div fxFlex="50" style="margin-top: 15px; font-size: 14px; font-weight: 300;">
                        <input [(ngModel)]="privacy" type="checkbox">
                        Accetto il trattamento dei dati personali, titolare dei dati Verrocchi Group Srl
                    </div>

                    <div fxFlex="50" fxLayout="row" fxLayoutAlign="end center" style="margin-top: 15px;">
                        <button (click)="sendEmail()" class="button-blue">Invia messaggio</button>
                    </div>
                </div>
            </div>
        </div>
        <div fxFlex="50" style="padding-left: 30px;">
            <div class="font-24-700 color-blue">
                Dove siamo - Map
            </div>
            <div style="margin-top: 30px;">
                <agm-map style="width:100%; height:500px;" [latitude]="latMap" [longitude]="lonMap" [(zoom)]="zoom" [mapTypeControl]="false">
                    <agm-marker *ngFor="let coord of coords; let i=index;" 
                        [latitude]="coord.lat" 
                        [longitude]="coord.lng" 
                        [zIndex]="coord.zIndex"
                        [iconUrl]="coord.iconUrl">
                    </agm-marker>
                </agm-map>
            </div>
            <div class="font-24-700 color-blue" style="margin-top: 60px;">
                Follow us
            </div>
            <div class="font-18-300">
                Seguici sui social network per restare sempre aggiornato
            </div>
            <div style="margin-top: 30px;">
                <i style="font-size: 50px; color:#006EAD; margin-right: 15px;" class="fab fa-facebook-square"></i>

                <i style="font-size: 50px; color:#006EAD;" class="fab fa-linkedin"></i>
            </div>
        </div>
    </div>
    
    <app-footer></app-footer>
</section>


<section *ngIf="!menu" fxHide fxShow.lt-md>

    <div style="position: relative;">
        <div style="background-image: url('./assets/images/contact/Contatti_header_desk.jpg'); background-position: center; background-size: cover; height: 40vh;">


        </div>

        <div style="width: 100%; position: absolute; z-index: 9; top: 0px;"> 
            <div fxLayout="row" fxLayoutAlign="start center" style="height: 150px;">
                <div (click)="openMenu()" fxFlex="20" fxLayout="row" fxLayoutAlign="start start" style="cursor: pointer; height: 150px;">
                    <i style="color:white; font-size:30px; margin-left:30px; margin-top:30px;" class="fas fa-bars"></i>
                </div>
                <div fxFlex="60" style="text-align: center;">
                    <img src="./assets/verrocchi_bianco.svg" width="150px">
                </div>
                <div fxFlex="20"></div>
            </div>
            <div fxLayout="row" fxLayoutAlign="start end" style="padding-bottom: 20px; height: calc( 40vh - 150px); width: 100%;">
                <div style="text-align: center; padding-left: 25px; padding-right: 10px;">
                    <img src="./assets/images/02crane.svg" width="50px">
                </div>
                <div style="padding-right: 25px; text-transform: uppercase; font-size: 26px; line-height: 24px;" class="font-34-700 color-white">
                    Contatti
                </div>
            </div> 
        </div>
    </div>


    <div style="padding-left: 25px; padding-top: 40px; padding-right: 25px; padding-bottom: 40px;">
        <div>
            <div class="font-24-700 color-blue">
                Verrocchi Group Srl
            </div>
            <div class="font-18-300">
                Via Porto Nuovo, 2<br>
                Scala D - P.1 Int. 32<br>
                70122 BARI - Italy<br>
                Tel +39 080.5246545<br>
                info@verrocchi.com
            </div>
            <div class="font-24-700 color-blue" style="margin-top: 40px;">
                Quick Mail
            </div>
            <div fxLayout="column" fxLayoutAlign="start start" >
                <input [(ngModel)]="nome" placeholder="Nome e Cognome" class="input-form" style="margin-top: 30px;">

                <input [(ngModel)]="company" placeholder="Azienda" class="input-form" style="margin-top: 15px;">

                <input [(ngModel)]="email" placeholder="Email" class="input-form" style="margin-top: 15px;">

                <input [(ngModel)]="phone" placeholder="Telefono" class="input-form" style="margin-top: 15px;">

                <textarea [(ngModel)]="message" placeholder="Scrivi messaggio" class="input-form" style="height: 200px; margin-top: 15px;"></textarea>

                <div *ngIf="error" style="background-color: #f05555; color:white; padding-left:10px; padding-top: 5px; padding-bottom: 5px; padding-right: 15px; margin-top:20px; font-size: 14px;">
                    {{error}}
                </div>

                <div *ngIf="success" style="background-color: green; color:white; padding-left:10px; padding-top: 5px; padding-bottom: 5px; padding-right: 15px; margin-top:20px; font-size: 14px;">
                    {{success}}
                </div>

                <div>
                    <div style="margin-top: 15px; font-size: 14px; font-weight: 300;">
                        <input [(ngModel)]="privacy" type="checkbox">
                        Accetto il trattamento dei dati personali, titolare dei dati Verrocchi Group Srl
                    </div>

                    <div fxLayout="row" fxLayoutAlign="end center" style="margin-top: 15px;">
                        <button (click)="sendEmail()" style="width: 100%;" class="button-blue">Invia messaggio</button>
                    </div>
                </div>
            </div>
        </div>
        <div style="margin-top: 40px;">
            <div class="font-24-700 color-blue">
                Dove siamo - Map
            </div>
            <div style="margin-top: 30px;">
                <agm-map style="width:100%; height:400px;" [latitude]="latMap" [longitude]="lonMap" [(zoom)]="zoom" [mapTypeControl]="false">
                    <agm-marker *ngFor="let coord of coords; let i=index;" 
                        [latitude]="coord.lat" 
                        [longitude]="coord.lng" 
                        [zIndex]="coord.zIndex"
                        [iconUrl]="coord.iconUrl">
                    </agm-marker>
                </agm-map>
            </div>
            <div class="font-24-700 color-blue" style="margin-top: 60px;">
                Follow us
            </div>
            <div class="font-18-300">
                Seguici sui social network per restare sempre aggiornato
            </div>
            <div style="margin-top: 30px;">
                <i style="font-size: 50px; color:#006EAD; margin-right: 15px;" class="fab fa-facebook-square"></i>

                <i style="font-size: 50px; color:#006EAD;" class="fab fa-linkedin"></i>
            </div>
        </div>
    </div>
    
    <app-footer></app-footer>
</section>



<div fxLayout="column" fxLayoutAlign="start center" *ngIf="menu" style="padding-bottom: 60px; width: 100%; height: 100vh; overflow-y: scroll; background-color: #0086D4;">
    <div style="padding-top: 30px; padding-bottom: 30px;">
        <img src="./assets/verrocchi_bianco.svg" width="200px">
    </div>
    <div (click)="openMenu()" style="cursor: pointer; position: absolute; right:20px; top:20px;">
        <i style="color:white; font-size:30px" class="fas fa-times"></i>
    </div>

    <div style="width: 100%; height: 1px; background-color:#0070B1;"></div>

    <div style="width: 100%;">
        <button (click)="openLink('home')" class="button-menu-mobile">HOME</button>
    </div>

    <div style="width: 100%; height: 1px; background-color:#0070B1;"></div>

    <div style="width: 100%;">
        <button (click)="openLink('spedizioni_internazionali')" class="button-menu-mobile">SPEDIZIONI INTERNAZIONALI</button>
    </div>

    <div style="width: 100%; height: 1px; background-color:#0070B1;"></div>

    <div style="width: 100%;">
        <button (click)="openLink('dogana')" class="button-menu-mobile">DOGANA</button>
    </div>

    <div style="width: 100%; height: 1px; background-color:#0070B1;"></div>

    <div style="width: 100%;">
        <button (click)="openLink('logistica')" class="button-menu-mobile">LOGISTICA</button>
    </div>

    <div style="width: 100%; height: 1px; background-color:#0070B1;"></div>

    <div style="width: 100%;">
        <button (click)="openLink('about')" class="button-menu-mobile">ABOUT US</button>
    </div>

    <div style="width: 100%; height: 1px; background-color:#0070B1;"></div>

    <div style="width: 100%;">
        <button (click)="openLink('contact')" class="button-menu-mobile">CONTATTI</button>
    </div>

    <div style="width: 100%; height: 1px; background-color:#0070B1;"></div>

    <div style="margin-top: 30px;">
        <i style="font-size: 40px; color:white; margin-right: 15px;" class="fab fa-facebook-square"></i>

        <i style="font-size: 40px; color:white;" class="fab fa-linkedin"></i>
    </div>
</div>