<section fxHide fxShow.md fxShow.gt-md>
    <div style="position: relative;">
        <div style="background-image: url('./assets/images/service/ServizioA_header_desk.jpg'); background-position: center; background-size: cover; height: 40vh;">


        </div>

        <div style="width: 100%; position: absolute; z-index: 9; top: 0px;"> 
            <div fxLayout="row" fxLayoutAlign="space-between center" style="height: 150px;">
                <div style="padding-left: 60px;">
                    <img src="./assets/verrocchi_bianco.svg" width="150px">
                </div>
                <div fxLayout="row" fxLayoutAlign="end center" style="padding-right: 60px;">
                    <div>
                        <button routerLink="/home" class="button-menu">HOME</button>
                    </div>
                    <div>
                        <button routerLink="/spedizioni_internazionali"  style="border-bottom: 2px solid white;" class="button-menu">SPEDIZIONI INTERNAZIONALI</button>
                    </div>
                    <div>
                        <button routerLink="/dogana" class="button-menu">DOGANA</button>
                    </div>
                    <div>
                        <button routerLink="/logistica" class="button-menu">LOGISTICA</button>
                    </div>
                    <div>
                        <button routerLink="/about" class="button-menu">ABOUT US</button>
                    </div>
                    <div>
                        <button routerLink="/contact" class="button-menu">CONTATTI</button>
                    </div>
                </div>
            </div>
            <div fxLayout="column" fxLayoutAlign="center center" style="height: calc( 40vh - 150px); width: 100%;">
                <div style="text-align: center;">
                    <img src="./assets/images/01shipping.svg" width="80px">
                </div>
                <div style="margin-top: 15px; text-transform: uppercase;" class="font-34-700 color-white">
                    SPEDIZIONI INTERNAZIONALI
                </div>
            </div> 
        </div>
    </div>

    <div style="padding-left: 120px; padding-top: 60px; padding-right: 120px; padding-bottom: 60px;">
        <div fxLayout="row" fxLayoutAlign="start center">
            <div style="margin-right: 30px;"><img src="./assets/images/service/Trasporti_stradali.svg" width="100px"/></div>
            <div class="font-24-700 color-red">Trasporti stradali</div>
        </div>
        <div class="font-18-300">
            Con sedi operative a Bari porto, interporto e zona industriale, e una recente sede branch in Albania, a Durazzo e Tirana, abbiamo intensificato i servizi di trasporto e la gestione dei traffici da/per l’Albania e tutta l’area Balcanica. Frequenti le partenze anche da/per la Turchia con diverse soluzioni logistiche per capacità, volumi e tempistiche di resa.
            Ci siamo specializzati nella gestione dei trasporti delle varie filiere sul modello tailor made, trattando: confezioni d’abbigliamento,  (fashion, capi appesi), tessuti, food & beverage (anche a temperatura controllata), componentistica ed impiantistica industriale, manifattura industriale, automotive parts, mezzi d’opera (escavatori, mezzi cingolati) automezzi industriali, veicoli commerciali, rinfuse liquide e solide, rifiuti speciali, ecc.
            <br><br>
            Di seguito le maggiori aree servite con trasporti stradali a livello internazionale:
            Svizzera, Austria, Germania, Francia, Spagna, Belgio, Olanda, Inghilterra, Svezia, Norvegia, Finlandia, Russia, Polonia, Croazia, Serbia, Montenegro, Albania, Kosovo, Macedonia, Grecia, Bulgaria, Romania, Turchia, Georgia, Azerbaigian, Turkmenistan.
            A livello nazionale possiamo vantare di un efficiente rete di trasporto grazie alla costante collaborazione con i maggiori vettori del settore, e non abbiamo difficoltà ad eseguire trasporti di qualsiasi tipologia, mettendo a disposizione mezzi dedicati, a carico completo o groupage (FTL - LTL), con capacità di gestione sia del traffico collettame che del traffico pallettizzato.
        </div>
    </div>

    <div>
        <img src="./assets/images/service/Spedizioni_internazionali_1.jpg" width="100%"> 
    </div>

    <div style="padding-left: 120px; padding-top: 60px; padding-right: 120px; padding-bottom: 60px;">
        <div fxLayout="row" fxLayoutAlign="start center">
            <div style="margin-right: 30px;"><img src="./assets/images/service/Trasporti_intermodali.svg" width="100px"/></div>
            <div class="font-24-700 color-red">Trasporti intermodali</div>
        </div>
        <div class="font-18-300">
            Laddove possibile prediligiamo l’intermodalità e l’utilizzo di tecnologie di trasporto più ecofriendly, (trasporto via treno, o combinato ferro-gomma) che oltre ad essere a minor impatto ambientale, si rilevano più flessibili in termini di gestione del carico, e per questo anche più economiche. Queste esigenze ci hanno spinto a stringere collaborazioni con i più importanti vettori multimodali, (truck, rail, sea) per offrire trasporti delle più svariate tipologie di merci (food&beverage, bulk liquids, chemical, IMO, CER).
            <br>
            Numerose le aree servite con questa modalità:<br>
            Svizzera, Austria, Germania, Francia, Spagna, Belgio, Olanda, Inghilterra, Svezia, Norvegia, Finlandia, Russia, Polonia.
        </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="start start" style="background-color: #F7F7F7; padding-left: 120px; padding-top: 60px; padding-right: 120px; padding-bottom: 60px;">
        <div fxFlex="50" style="padding-right: 15px;">
            <div fxLayout="row" fxLayoutAlign="start center">
                <div style="margin-right: 30px;"><img src="./assets/images/service/Trasporti_marittimi.svg" width="100px"/></div>
                <div class="font-24-700 color-red">Trasporti marittimi</div>
            </div>
            <div class="font-18-300">
                Il metodo di spedizione via mare più diffuso e utilizzato è quello a mezzo container. E per tale ragione mettiamo a disposizione del cliente la nostra esperienza e le nostre competenze per offrire la migliore soluzione in termini economici, di sicurezza e di resa per quanto riguarda i trasporti containerizzati da e per tutti i principali porti del mondo (Cina, Far East, Americhe) curando nel dettaglio ogni minimo particolare, dalla stipula dell’assicurazioni sulle merci (all risk), al ritiro o consegna della merce, occupandoci di posizionamenti container in tutta Italia, di consolidamento/deconsolidamento partite groupage (LCL), gestione carichi completi (FCL), special equipments (Flat Rack, Open Top, Flexi Tank, Iso Tank) e tanto altro ancora.
                <br><br>
                Vantiamo una discreta esperienza anche per quanto riguarda la gestione di trasporti bulk (rinfuse solide o liquide) e break bulk (project cargo, mezzi d’opera, impiantistica industriale, ecc.) a mezzo di navi cargo o RO-RO, occupandoci del noleggio, del brokeraggio marittimo,  delle operazioni di carico e scarico a banchina, dei trasporti stradali ed eccezionali.
            </div>
        </div>
        <div fxFlex="50" style="padding-left: 15px;">
            <img src="./assets/images/service/spedizioni_2.jpg" width="100%"> 
        </div>
    </div>


    <div style="padding-left: 120px; padding-top: 60px; padding-right: 120px; padding-bottom: 60px;">
        <div fxLayout="row" fxLayoutAlign="start center">
            <div style="margin-right: 30px;"><img src="./assets/images/service/Trasporti_aerei.svg" width="100px"/></div>
            <div class="font-24-700 color-red">Trasporti aerei</div>
        </div>
        <div class="font-18-300">
            Per determinate tipologie di merci e ed esigenze particolari, la spedizione aerea risulta essere la più indicata (spedizioni urgenti, transit time ridotto, piccole partite e lunghe distanze).
            <br>Attraverso la nostra efficiente rete internazionale offriamo servizi di trasporto merci aereo, in collaborazione con i più affidabili agenti cargo IATA, avvalendoci delle principali compagnie aeree, ed assicurando che le esigenze di carico del cliente siano soddisfatte attraverso una gamma variegata di soluzioni.
            <br><br>
            In base alle necessità richieste, per merci in partenza possiamo provvedere al ritiro delle partite, consolidamento e condizionamento (pick & pack) su tutto il territorio nazionale, dirottando la spedizione nel flusso più congeniale avendo a disposizione partenze giornaliere dai maggiori aeroporti Italiani come Napoli, Roma, Milano, Venezia (ma anche Cagliari, Catania, Bari, Ancona, Bologna, Torino, Genova). Alla stessa stregua provvediamo alla gestione delle spedizioni in arrivo sugli scali aeroportuali Italiani sopracitati.
        </div>
    </div>

    <!--
    <div fxLayout="row" fxLayoutAlign="center start" style="margin-top: 60px; margin-bottom: 120px;">
        <div style="padding-left: 50px; padding-right: 50px; width: 200px; text-align: center;">
            <img src="./assets/images/service/A1-shipping.svg" width="100px">
            <div class="font-24-700 color-red" style="margin-top: 10px;"> 
                Box/icona one
            </div>
            <div class="font-18-300">
                Il trasporto merci svolge una funzione molto complessa e critica rispetto al passato.
            </div>
        </div>
        <div style="padding-left: 50px; padding-right: 50px; width: 200px; text-align: center;">
            <img src="./assets/images/service/A2-shipping.svg" width="100px">
            <div class="font-24-700 color-red" style="margin-top: 10px;">
                Box/icona one
            </div>
            <div class="font-18-300">
                Il trasporto merci svolge una funzione molto complessa e critica rispetto al passato.
            </div>
        </div>
        <div style="padding-left: 50px; padding-right: 50px; width: 200px; text-align: center;">
            <img src="./assets/images/service/A3-shipping.svg" width="100px">
            <div class="font-24-700 color-red" style="margin-top: 10px;">
                Box/icona one
            </div>
            <div class="font-18-300">
                Il trasporto merci svolge una funzione molto complessa e critica rispetto al passato.
            </div>
        </div>
    </div>
    -->

    <div style="background-image: url('./assets/images/service/Box_contattaci_servizio_desk.jpg'); background-position: center; background-size: cover; height: 50vh;">
        <div fxLayout="column" fxLayoutAlign="center center" style="padding-left: 30px; padding-right: 30px; background-color: rgba(0, 0, 0, 0.4); height: 50vh;">
            <div class="font-40-700 color-white" style="text-align: center; line-height: 40px; margin-top: 30px;">
                Flessibilità, praticità e semplicità<br>dei processi di spedizione.
            </div>   
            <div style="margin-top: 30px;">
                <button routerLink="/contact" class="button-white">CONTATTACI</button>
            </div>
        </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="center start" style="margin-top: 60px; margin-bottom: 60px;">
        <div routerLink="/dogana" style="cursor: pointer; padding-left: 50px; padding-right: 50px; width: 250px; height: 350px; padding-top: 30px; padding-bottom: 30px; margin-right: 5px; margin-left: 5px; text-align: center; background-color: #0061C3;">
            <img src="./assets/images/02crane.svg" width="100px">
            <div class="font-24-700 color-white" style="margin-top: 10px; line-height: 25px;">
                Dogana
            </div>
            <div class="font-18-300 color-white" style="margin-top: 10px;">
                Da oltre 40 anni, fiore all’occhiello della nostra attività, le operazioni doganali sono il nostro pane quotidiano, e il punto cruciale di ogni spedizione che coinvolga uno o più paesi comunitari ed extra-ue.
            </div>
        </div>
        <div routerLink="/logistica" style="cursor: pointer; padding-left: 50px; padding-right: 50px; width: 250px; height: 350px; padding-top: 30px; padding-bottom: 30px; margin-right: 5px; margin-left: 5px; text-align: center; background-color: #2DC300;">
            <img src="./assets/images/03tracking.svg" width="100px">
            <div class="font-24-700 color-white" style="margin-top: 10px; line-height: 25px;">
                Logistica
            </div>
            <div class="font-18-300 color-white" style="margin-top: 10px;">
                A fronte di sempre più repentini cambiamenti del mercato e dei mutabili scenari del commercio internazionale, una lucida e reattiva gestione della catena logistica può fare la differenza nelle voci di bilancio aziendale
            </div>
        </div>
    </div>

    <app-footer></app-footer>
</section>



<section *ngIf="!menu" fxHide fxShow.lt-md>
    
    <div style="position: relative;">
        <div style="background-image: url('./assets/images/service/ServizioA_header_desk.jpg'); background-position: center; background-size: cover; height: 40vh;">


        </div>

        <div style="width: 100%; position: absolute; z-index: 9; top: 0px;"> 
            <div fxLayout="row" fxLayoutAlign="start center" style="height: 150px;">
                <div (click)="openMenu()" fxFlex="20" fxLayout="row" fxLayoutAlign="start start" style="cursor: pointer; height: 150px;">
                    <i style="color:white; font-size:30px; margin-left:30px; margin-top:30px;" class="fas fa-bars"></i>
                </div>
                <div fxFlex="60" style="text-align: center;">
                    <img src="./assets/verrocchi_bianco.svg" width="150px">
                </div>
                <div fxFlex="20"></div>
            </div>
            <div fxLayout="row" fxLayoutAlign="start end" style="padding-bottom: 20px; height: calc( 40vh - 150px); width: 100%;">
                <div style="text-align: center; padding-left: 25px; padding-right: 10px;">
                    <img src="./assets/images/01shipping.svg" width="50px">
                </div>
                <div style="padding-right: 25px; text-transform: uppercase; font-size: 26px; line-height: 24px;" class="font-34-700 color-white">
                    SPEDIZIONI INTERNAZIONALI
                </div>
            </div> 
        </div>
    </div>

    
    <div style="padding-left: 25px; padding-top: 40px; padding-right: 25px; padding-bottom: 40px;">
        <div fxLayout="row" fxLayoutAlign="start center">
            <div style="margin-right: 15px;"><img src="./assets/images/service/Trasporti_stradali.svg" width="60px"/></div>
            <div class="font-24-700 color-red">Trasporti stradali</div>
        </div>
        <div class="font-16-300">
            Con sedi operative a Bari porto, interporto e zona industriale, e una recente sede branch in Albania, a Durazzo e Tirana, abbiamo intensificato i servizi di trasporto e la gestione dei traffici da/per l’Albania e tutta l’area Balcanica. Frequenti le partenze anche da/per la Turchia con diverse soluzioni logistiche per capacità, volumi e tempistiche di resa.
            Ci siamo specializzati nella gestione dei trasporti delle varie filiere sul modello tailor made, trattando: confezioni d’abbigliamento,  (fashion, capi appesi), tessuti, food & beverage (anche a temperatura controllata), componentistica ed impiantistica industriale, manifattura industriale, automotive parts, mezzi d’opera (escavatori, mezzi cingolati) automezzi industriali, veicoli commerciali, rinfuse liquide e solide, rifiuti speciali, ecc.
            <br><br>
            Di seguito le maggiori aree servite con trasporti stradali a livello internazionale:
            Svizzera, Austria, Germania, Francia, Spagna, Belgio, Olanda, Inghilterra, Svezia, Norvegia, Finlandia, Russia, Polonia, Croazia, Serbia, Montenegro, Albania, Kosovo, Macedonia, Grecia, Bulgaria, Romania, Turchia, Georgia, Azerbaigian, Turkmenistan.
            A livello nazionale possiamo vantare di un efficiente rete di trasporto grazie alla costante collaborazione con i maggiori vettori del settore, e non abbiamo difficoltà ad eseguire trasporti di qualsiasi tipologia, mettendo a disposizione mezzi dedicati, a carico completo o groupage (FTL - LTL), con capacità di gestione sia del traffico collettame che del traffico pallettizzato.
        </div>
    </div>

    <div>
        <img src="./assets/images/service/Spedizioni_internazionali_1.jpg" width="100%"> 
    </div>

    <div style="padding-left: 25px; padding-top: 40px; padding-right: 25px; padding-bottom: 60px;">
        <div fxLayout="row" fxLayoutAlign="start center">
            <div style="margin-right: 15px;"><img src="./assets/images/service/Trasporti_intermodali.svg" width="60px"/></div>
            <div class="font-24-700 color-red">Trasporti intermodali</div>
        </div>
        <div class="font-16-300">
            Laddove possibile prediligiamo l’intermodalità e l’utilizzo di tecnologie di trasporto più ecofriendly, (trasporto via treno, o combinato ferro-gomma) che oltre ad essere a minor impatto ambientale, si rilevano più flessibili in termini di gestione del carico, e per questo anche più economiche. Queste esigenze ci hanno spinto a stringere collaborazioni con i più importanti vettori multimodali, (truck, rail, sea) per offrire trasporti delle più svariate tipologie di merci (food&beverage, bulk liquids, chemical, IMO, CER).
            <br>
            Numerose le aree servite con questa modalità:<br>
            Svizzera, Austria, Germania, Francia, Spagna, Belgio, Olanda, Inghilterra, Svezia, Norvegia, Finlandia, Russia, Polonia.
        </div>
    </div>

    <div style="background-color: #F7F7F7; padding-left: 25px; padding-top: 40px; padding-right: 25px; padding-bottom: 40px;">
        <div style="padding-right: 15px;">
            <div fxLayout="row" fxLayoutAlign="start center">
                <div style="margin-right: 15px;"><img src="./assets/images/service/Trasporti_marittimi.svg" width="60px"/></div>
                <div class="font-24-700 color-red">Trasporti marittimi</div>
            </div>
            <div class="font-16-300">
                Il metodo di spedizione via mare più diffuso e utilizzato è quello a mezzo container. E per tale ragione mettiamo a disposizione del cliente la nostra esperienza e le nostre competenze per offrire la migliore soluzione in termini economici, di sicurezza e di resa per quanto riguarda i trasporti containerizzati da e per tutti i principali porti del mondo (Cina, Far East, Americhe) curando nel dettaglio ogni minimo particolare, dalla stipula dell’assicurazioni sulle merci (all risk), al ritiro o consegna della merce, occupandoci di posizionamenti container in tutta Italia, di consolidamento/deconsolidamento partite groupage (LCL), gestione carichi completi (FCL), special equipments (Flat Rack, Open Top, Flexi Tank, Iso Tank) e tanto altro ancora.
                <br><br>
                Vantiamo una discreta esperienza anche per quanto riguarda la gestione di trasporti bulk (rinfuse solide o liquide) e break bulk (project cargo, mezzi d’opera, impiantistica industriale, ecc.) a mezzo di navi cargo o RO-RO, occupandoci del noleggio, del brokeraggio marittimo,  delle operazioni di carico e scarico a banchina, dei trasporti stradali ed eccezionali.
            </div>
        </div>
        <div style="margin-top: 20px;">
            <img src="./assets/images/service/spedizioni_2.jpg" width="100%"> 
        </div>
    </div>


    <div style="padding-left: 25px; padding-top: 40px; padding-right: 25px; padding-bottom: 40px;">
        <div fxLayout="row" fxLayoutAlign="start center">
            <div style="margin-right: 15px;"><img src="./assets/images/service/Trasporti_aerei.svg" width="60px"/></div>
            <div class="font-24-700 color-red">Trasporti aerei</div>
        </div>
        <div class="font-16-300">
            Per determinate tipologie di merci e ed esigenze particolari, la spedizione aerea risulta essere la più indicata (spedizioni urgenti, transit time ridotto, piccole partite e lunghe distanze).
            <br>Attraverso la nostra efficiente rete internazionale offriamo servizi di trasporto merci aereo, in collaborazione con i più affidabili agenti cargo IATA, avvalendoci delle principali compagnie aeree, ed assicurando che le esigenze di carico del cliente siano soddisfatte attraverso una gamma variegata di soluzioni.
            <br><br>
            In base alle necessità richieste, per merci in partenza possiamo provvedere al ritiro delle partite, consolidamento e condizionamento (pick & pack) su tutto il territorio nazionale, dirottando la spedizione nel flusso più congeniale avendo a disposizione partenze giornaliere dai maggiori aeroporti Italiani come Napoli, Roma, Milano, Venezia (ma anche Cagliari, Catania, Bari, Ancona, Bologna, Torino, Genova). Alla stessa stregua provvediamo alla gestione delle spedizioni in arrivo sugli scali aeroportuali Italiani sopracitati.
        </div>
    </div>
    
    <div style="background-image: url('./assets/images/home/Home_contattaci_desk.jpg'); background-position: center; background-size: cover; height: 50vh;">
        <div fxLayout="column" fxLayoutAlign="center center" style="padding-left: 15px; padding-right: 15px; background-color: rgba(0, 0, 0, 0.4); height: 50vh;">
            <div class="font-40-700 color-white" style="font-size: 30px; line-height: 30px !important; text-align: center; line-height: 40px; margin-top: 30px;">
                Flessibilità, praticità e semplicità<br>dei processi di spedizione.
            </div>   
            <div style="margin-top: 30px;">
                <button routerLink="/contact" class="button-white">CONTATTACI</button>
            </div>
        </div>
    </div>

    <div fxLayout="column" fxLayoutAlign="center center" style="margin-top: 40px; margin-bottom: 40px;">
        <div routerLink="/dogana" style="cursor: pointer; padding-left: 50px; padding-right: 50px; width: 250px; height: 350px; padding-top: 30px; padding-bottom: 30px; margin-right: 5px; margin-left: 5px; text-align: center; background-color: #0061C3;">
            <img src="./assets/images/02crane.svg" width="60px">
            <div class="font-24-700 color-white" style="margin-top: 10px; line-height: 25px;">
                Dogana
            </div>
            <div class="font-18-300 color-white" style="margin-top: 10px;">
                Da oltre 40 anni, fiore all’occhiello della nostra attività, le operazioni doganali sono il nostro pane quotidiano, e il punto cruciale di ogni spedizione che coinvolga uno o più paesi comunitari ed extra-ue.
            </div>
        </div>
        <div routerLink="/logistica" style="margin-top: 40px; cursor: pointer; padding-left: 50px; padding-right: 50px; width: 250px; height: 350px; padding-top: 30px; padding-bottom: 30px; margin-right: 5px; margin-left: 5px; text-align: center; background-color: #2DC300;">
            <img src="./assets/images/03tracking.svg" width="60px">
            <div class="font-24-700 color-white" style="margin-top: 10px; line-height: 25px;">
                Logistica
            </div>
            <div class="font-18-300 color-white" style="margin-top: 10px;">
                A fronte di sempre più repentini cambiamenti del mercato e dei mutabili scenari del commercio internazionale, una lucida e reattiva gestione della catena logistica può fare la differenza nelle voci di bilancio aziendale
            </div>
        </div>
    </div>

    <app-footer></app-footer>
</section>



<div fxLayout="column" fxLayoutAlign="start center" *ngIf="menu" style="padding-bottom: 60px; width: 100%; height: 100vh; overflow-y: scroll; background-color: #0086D4;">
    <div style="padding-top: 30px; padding-bottom: 30px;">
        <img src="./assets/verrocchi_bianco.svg" width="200px">
    </div>
    <div (click)="openMenu()" style="cursor: pointer; position: absolute; right:20px; top:20px;">
        <i style="color:white; font-size:30px" class="fas fa-times"></i>
    </div>

    <div style="width: 100%; height: 1px; background-color:#0070B1;"></div>

    <div style="width: 100%;">
        <button (click)="openLink('home')" class="button-menu-mobile">HOME</button>
    </div>

    <div style="width: 100%; height: 1px; background-color:#0070B1;"></div>

    <div style="width: 100%;">
        <button (click)="openLink('spedizioni_internazionali')" class="button-menu-mobile">SPEDIZIONI INTERNAZIONALI</button>
    </div>

    <div style="width: 100%; height: 1px; background-color:#0070B1;"></div>

    <div style="width: 100%;">
        <button (click)="openLink('dogana')" class="button-menu-mobile">DOGANA</button>
    </div>

    <div style="width: 100%; height: 1px; background-color:#0070B1;"></div>

    <div style="width: 100%;">
        <button (click)="openLink('logistica')" class="button-menu-mobile">LOGISTICA</button>
    </div>

    <div style="width: 100%; height: 1px; background-color:#0070B1;"></div>

    <div style="width: 100%;">
        <button (click)="openLink('about')" class="button-menu-mobile">ABOUT US</button>
    </div>

    <div style="width: 100%; height: 1px; background-color:#0070B1;"></div>

    <div style="width: 100%;">
        <button (click)="openLink('contact')" class="button-menu-mobile">CONTATTI</button>
    </div>

    <div style="width: 100%; height: 1px; background-color:#0070B1;"></div>

    <div style="margin-top: 30px;">
        <i style="font-size: 40px; color:white; margin-right: 15px;" class="fab fa-facebook-square"></i>

        <i style="font-size: 40px; color:white;" class="fab fa-linkedin"></i>
    </div>
</div>