import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  //mappa
  public mappa = false;
  public zoom = 14;
  public latMap = 41.1321743;
  public lonMap = 16.8691807;
  public coords = [];


  public nome;
  public company;
  public email;
  public phone;
  public message;
  public privacy = false;

  public error = null;
  public success = null;

  constructor(private router: Router, private http: HttpClient) { 
    this.addcoords();
  }

  ngOnInit(): void {
    window.scroll(0,0);
  }

  addcoords(){

    var doc = {
      id: 1,
      zIndex : 1,
      lat: 41.1321743,
      lng: 16.8691807,
      /*iconUrl : {
        url: 'assets/img/marker_map.svg',
        scaledSize: {
          width: 40,
          height: 40,
        },
      }
      */
    }

    this.coords.push(doc);
  }

  public menu = false;
  openMenu(){
    if(this.menu){
      this.menu = false;
    }else{
      this.menu = true;
    }
  }

  openLink(link){
    this.menu = false;
    this.router.navigate([link]);
  }

  sendEmail(){
    this.error = null;
    this.success = null;

    if(this.privacy){
      var self = this;

      if(this.nome && this.company && this.phone && this.email && this.message){
        const body = {
          name: this.nome,
          company: this.company,
          email: this.email,
          phone: this.phone,
          message: this.message,
        };

        console.log(body);
        self.success = 'Messaggio inviato correttamente.';
        self.http.post('https://node4036-composing-studio.cloud.jelastic.open.tim.it/v1/verrocchi/emails/info', body)
        .subscribe(
          res => {
            console.log(res);
          },
          err => {
            console.log(err); 
            //self.error = 'Si è verificato une errore. Riprova più tardi.';
          }   
        )
      }else{
        this.error = 'Inserisci tutti i tuoi dati.';
      }
    }else{
      this.error = 'Devi accettare il trattamento dei dati personali.';
    }
  }
}
